@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.reference-block {
    width: 100%;
    position: relative;
    margin-bottom: 6rem;

    .logo {
        width: 80px;
        height: 80px;

        img {
            width: 100%;
            height: 100%;
            display: block;
            font-size: 0;
            margin: 0;
            padding: 0;
        }
    }

    .name {
        font-weight: 700;
        letter-spacing: 0.5px;
        margin-top: 20px;
        margin-bottom: 0;
    }

    .company {
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .title {
        margin-bottom: 20px;
    }

    .tel,
    .email {
        svg path {
            fill: $grey !important;
        }
    }
}
