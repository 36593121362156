@import '../../theme/Mixins';
@import '../../theme/Functions';
@import '../../theme/Variables';

#contact {
    .icon-w-text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        svg {
            width: 5rem;
            margin-right: 10px;
        }
    }
}
