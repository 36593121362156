@import './Functions';
@import './Mixins';
@import './Variables';

// Background colors
.bg-is- {
    &black {
        background-color: $black;

        &-gradient {
            @include gradient-bg(to bottom right, lighten($black, 5%), $black);
        }
    }
    &white {
        background-color: $white;
    }
    &grey {
        background-color: $grey;
    }
    &purple {
        background-color: $purple;
    }
    &teal {
        background-color: $teal;
    }
    &red {
        background-color: $red;
    }
}
