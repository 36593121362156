@import './Variables';

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
    word-wrap: break-word;
    letter-spacing: -2px;
}

h1 {
    font-size: 10rem;
    line-height: 0.8em;
}
h2 {
    font-size: 8.5rem;
}
h3 {
    font-size: 7rem;
}
h4 {
    font-size: 5.5rem;
}
h5 {
    font-size: 4rem;
}
h6 {
    font-size: 2.5rem;
}

p {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: inherit;
}

// colors

.text-is- {
    &dark {
        color: $black;
    }
    &light {
        color: $grey;
    }
}
