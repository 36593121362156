@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.timeline-entry {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 10px 0 60px 0;
    padding-top: 30px;
    background: $purple;

    @media #{$medium-and-up} {
        background: transparent;
        padding-top: 0;
        margin: 0;
        flex-direction: column;
    }

    &:after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        @include transform(translateX(-50%));
        background: $black;
        border: 5px solid $purple;
        @include border-radius(50%);
    }

    &.is- {
        &left {
            @media #{$medium-and-up} {
                text-align: right;
                flex-direction: row;
                margin-left: 25px;

                &:after {
                    right: -10px;
                    left: initial;
                    margin: 0;
                    @include transform(translateX(0));
                }

                .content {
                    margin-right: 20px;
                }
            }
        }
        &right {
            @media #{$medium-and-up} {
                text-align: left;
                flex-direction: row-reverse;
                margin-left: -25px;

                &:after {
                    left: -10px;
                    right: initial;
                    margin: 0;
                    @include transform(translateX(0));
                }

                .content {
                    margin-left: 20px;
                }
            }
        }
    }

    .title {
        font-weight: 700;
    }

    .dates {
        letter-spacing: -0.5px;
    }

    img {
        width: 5rem;
        height: 5rem;
        border: 2px solid $black;
        @include border-radius(50%);
        margin-bottom: 20px;
        flex: 0 0 5rem;

        @media #{$medium-and-up} {
            margin-bottom: 0;
        }
    }
}
