@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.skill {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;

    &:last-child {
        @media #{$medium-and-up} {
            margin-bottom: 0;
        }
    }

    .title {
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        color: $black;
        text-align: right;
    }

    .rating {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .dot {
            width: 25px;
            height: 25px;
            @include border-radius(50%);
            background: mix($grey, $red, 80%);
            margin-left: 7px;

            &.active {
                background: $red;
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}
