@import '../../theme/Variables';

#hero {
    padding-top: 15rem;

    @media #{$medium-only} {
        padding-top: 30rem;
    }

    @media #{$large-and-up} {
        padding-top: 0;
    }

    .myself {
        width: 100vw;
        height: 50vh;
        position: absolute;
        bottom: 0;
        right: 0;
        background: url('../../assets/myself-gradient.png') no-repeat;
        background-size: contain;
        background-position: top center;

        @media #{$large-and-up} {
            width: 60vw;
            height: 95vh;
            background-size: cover;
            background-position: top left;
        }
    }
}
