@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.education-card {
    width: 100%;
    padding: 30px;
    background: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30px;
    position: relative;

    .tag {
        background: $purple;
        position: absolute;
        top: -12px;
        left: 30px;
        z-index: 2;
        color: $grey;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        padding: 7px;
        letter-spacing: 2px;
        margin: 0;
        line-height: 1;
    }

    .logo {
        width: 80px;
        height: 80px;
        @include border-radius(50%);

        img {
            width: 100%;
            height: 100%;
        }
    }

    .dates,
    .title {
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 1;
    }

    .description {
        margin-top: 5px;
        margin-bottom: 0;
    }
}
