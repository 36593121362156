// Colors
$black: #0e0622;
$grey: #dcd5e3;
$purple: #532d74;
$teal: #2cfff6;
$red: #d90b39;
$white: #ffffff;

// Fonts
$primary-font: 'Mont';

// Animation and Easing
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;

$duration: 0.2s;
$ease: $animation-curve-fast-out-slow-in;

// Breakpoints
$small-and-down: '(max-width: 767.98px)';
$small-and-up: '(min-width: 576px)';
$small-only: '(min-width: 576px) and (max-width: 767.98px)';
$medium-and-down: '(max-width: 991.98px)';
$medium-and-up: '(min-width: 768px)';
$medium-only: '(min-width: 768px) and (max-width: 991.98px)';
$large-and-down: '(max-width: 1199.98px)';
$large-and-up: '(min-width: 992px)';
$large-only: '(min-width: 992px) and (max-width: 1199.98px)';
$xlarge-and-up: '(min-width: 1200px)';
