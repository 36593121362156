@import '../theme/Functions';
@import '../theme/Mixins';
@import '../theme/Variables';

.section-title {
    position: absolute;
    left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 20;

    @media #{$medium-and-up} {
        left: 2rem;
    }

    @media #{$xlarge-and-up} {
        left: 6rem;
    }

    &.is- {
        &up {
            bottom: -7px;
            @include transform(rotate(-90deg));
            transform-origin: left;
        }
        &down {
            top: -7px;
            @include transform(rotate(90deg));
            transform-origin: left;
        }
    }

    .stripe {
        width: 100px;
        height: 1px;

        &.is- {
            &dark {
                background: $black;
            }
            &light {
                background: $grey;
            }
        }
    }

    .title {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1;
        padding-left: 5px;
        margin-top: 3px;

        &.is- {
            &dark {
                color: $black;
            }
            &light {
                color: $grey;
            }
        }
    }
}
