@import './Mixins';
@import './Functions';
@import './Variables';
@import './fonts/Fonts';
@import './TextStyles';
@import './FillColors';
@import './Carousel';

html,
body {
    font-family: $primary-font;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.3rem;
    color: $black;
}

* {
    &::selection {
        background: $red;
        color: $black;
    }
}
