@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.nlogo {
    position: fixed;
    top: 15px;
    left: 20px;
    z-index: 100;

    @media #{$medium-and-up} {
        top: 23px;
    }

    img {
        width: 30px;

        @media #{$medium-and-up} {
            width: 40px;
        }
    }
}
.navbar {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: $grey;
    padding: 30px 10px;
    // margin-left: 0;
    @include transition(all $duration $ease);
    border: 0;

    @media #{$medium-and-up} {
        padding-left: 70px;
    }

    &.at-top {
        background: transparent;
    }

    .menu-close {
        position: absolute;
        left: 50%;
        @include transform(translateX(-50%));
        top: 20px;
    }

    .menu-toggle {
        position: absolute;
        left: 50%;
        @include transform(translateX(-50%));
    }

    .navitems {
        width: 100vw;
        position: relative;

        &.is-mobile {
            height: 100vh;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: $grey;
            position: absolute;
            top: 0;
            left: 0;

            .btn {
                font-size: 2.5rem;
                font-weight: 900;
                padding: 10px 0;
                margin: 0;
            }
        }

        @media #{$large-and-up} {
            position: relative;
            background: transparent;
        }

        .btn {
            margin: 0;
            padding: 5px 10px;
            margin-left: 5px;
            background: transparent;
            color: $black;
            font-size: 1.2rem;
            text-transform: initial;

            &:hover,
            &:active,
            &:focus {
                background: transparent;
                color: $black;
            }
        }
    }
}
