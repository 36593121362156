/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2020 */

@font-face {
    font-family: 'Mont';
    src: url('mont-black-webfont.woff2') format('woff2'),
        url('mont-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('mont-bold-webfont.woff2') format('woff2'),
        url('mont-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('mont-semibold-webfont.woff2') format('woff2'),
        url('mont-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('mont-book-webfont.woff2') format('woff2'),
        url('mont-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
