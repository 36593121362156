// gradient background
@mixin gradient-bg($conf...) {
    background: -webkit-linear-gradient($conf);
    background: -moz-linear-gradient($conf);
    background: -o-linear-gradient($conf);
    background: linear-gradient($conf);
}

// border radius
@mixin border-radius($conf...) {
    -webkit-border-radius: $conf;
    -moz-border-radius: $conf;
    border-radius: $conf;
}

// box shadow
@mixin box-shadow($conf...) {
    -webkit-box-shadow: $conf;
    -moz-box-shadow: $conf;
    box-shadow: $conf;
}

// text shadow
@mixin text-shadow($conf...) {
    -webkit-text-shadow: $conf;
    -moz-text-shadow: $conf;
    text-shadow: $conf;
}

// Transform
@mixin transform($conf...) {
    -webkit-transform: $conf;
    -moz-transform: $conf;
    transform: $conf;
}

// Transition
@mixin transition($conf...) {
    -webkit-transition: $conf;
    -moz-transition: $conf;
    transition: $conf;
}
