.owl-carousel {
    .owl-dots {
        width: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .owl-dot {
            width: 20px;
            height: 5px;
            @include border-radius(100px);
            background: $purple;
            opacity: 0.2;
            margin: 0 2px;

            &.active {
                opacity: 1;
            }
        }
    }
}
