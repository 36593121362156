@import '../../theme/Mixins';
@import '../../theme/Functions';
@import '../../theme/Variables';

#experience {
    .timeline {
        .line {
            width: 2px;
            height: 100%;
            position: absolute;
            left: 50%;
            @include transform(translateX(-50%));
            @include border-radius(100px);
            background: $black;
            z-index: 0;
        }
    }
}
