@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.btn {
    width: 90%;
    padding: 25px 30px;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    @include border-radius(0);
    @include transition(background $duration $ease);
    margin-left: 0;
    margin-bottom: 20px;
    position: relative;

    @media #{$medium-and-up} {
        width: auto;
        margin-bottom: 0;
        margin-left: 20px;

        &:first-of-type {
            margin: 0;
        }
    }

    &.is- {
        &purple {
            background: $purple;
            color: $teal;

            &:hover,
            &:active,
            &:focus {
                background: shade($purple, 20%);
            }
        }
        &black {
            background: $black;
            color: $teal;

            &:hover,
            &:active,
            &:focus {
                background: tint($black, 20%);
            }
        }
    }
}
