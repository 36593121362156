@import '../theme/Mixins';
@import '../theme/Functions';
@import '../theme/Variables';

.phonetics {
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 0.5;

    &.is- {
        &light {
            color: $purple;
        }
        &dark {
            color: $black;
        }
    }
}
